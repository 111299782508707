<script lang="ts">
    export let size = 'size-6';
</script>

<svg width="27" height="27" viewBox="0 0 2549 2549" fill="none" xmlns="http://www.w3.org/2000/svg" class="{size}">
    <circle cx="1274.5" cy="1274.5" r="1274.5" fill="#040404"></circle>
    <path
        d="M1820.2 1073.4C1820.2 1138.47 1805.27 1198.2 1775.4 1252.6C1745.53 1305.93 1699.67 1349.13 1637.8 1382.2C1575.93 1415.27 1499.13 1431.8 1407.4 1431.8H1237.8V1835H964.2V711.8H1407.4C1497 711.8 1572.73 727.267 1634.6 758.2C1696.47 789.133 1742.87 831.8 1773.8 886.2C1804.73 940.6 1820.2 1003 1820.2 1073.4ZM1386.6 1214.2C1438.87 1214.2 1477.8 1201.93 1503.4 1177.4C1529 1152.87 1541.8 1118.2 1541.8 1073.4C1541.8 1028.6 1529 993.933 1503.4 969.4C1477.8 944.867 1438.87 932.6 1386.6 932.6H1237.8V1214.2H1386.6Z"
        fill="white"
    ></path>
</svg>
